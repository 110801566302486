import { Directive, effect, inject, TemplateRef, ViewContainerRef } from '@angular/core';

import { MobileAppDetectorService } from '@ppg/core/services';

@Directive({
  selector: '[ppgHideOnMobileAppView]',
  standalone: true,
})
export class HideOnMobileAppViewDirective {
  readonly #mobileAppDetectorService = inject(MobileAppDetectorService);
  readonly #view = inject(ViewContainerRef);
  readonly #template = inject(TemplateRef);

  constructor() {
    effect(() => {
      if (this.#mobileAppDetectorService.isMobileApp()) {
        this.#view.clear();
      } else {
        this.#view.createEmbeddedView(this.#template);
      }
    });
  }
}
