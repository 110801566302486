import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from '../consts/breakpoints';
import { PrimerBreakpoints } from '../consts/primer-breakpoints';
import { PrimeNgBreakpoints } from '../enums/primeng-breakpoints.enum';
import { getInPx } from './../functions/breakpoint-transform';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  get isMobileView(): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${getInPx(Breakpoints.Mobile)})`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }

  get isMobileTableView(): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${getInPx(PrimerBreakpoints.MobileTable)})`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }

  observePrimeNgBreakpoint(breakpoint: PrimeNgBreakpoints): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${getInPx(breakpoint)})`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }

  observeMinPrimeNgBreakpoint(breakpoint: PrimeNgBreakpoints): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(min-width: ${getInPx(breakpoint)})`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }
}
