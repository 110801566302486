import { Breakpoints } from './../consts/breakpoints';

export const DEFAULT_ROOT_ELEM_FONT_SIZE = 16;

export function getInPx(breakpoint: Breakpoints | number) {
  return `${breakpoint}px`;
}

export function getInRem(breakpoint: Breakpoints | number) {
  return `${breakpoint / DEFAULT_ROOT_ELEM_FONT_SIZE}rem`;
}
