export enum PrimeNgBreakpoints {
  Phone = 576,
  PhoneNoninclusive = 575.98,
  Table = 768,
  TableNoninclusive = 767.98,
  Notebook = 992,
  NotebookNoninclusive = 991.98,
  Desktop = 1200,
  DesktopNoninclusive = 1199.98,
}
